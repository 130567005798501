<template>
    <router-view class="full-height-layout" :carrier-data="carrierData" :service-level-data="serviceLevelData" :service-level-configuration-data="serviceLevelConfigurationData" ref="routeview"></router-view>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'ServiceLevelConfigurationView',
    props: ['carrierData', 'serviceLevelData'],
    data () {
        return {
            serviceLevelConfigurationData: null,
        }
    },
    computed: {
        breadcrumbElement () {
            return {
                name: (this.serviceLevelData && this.serviceLevelData.serviceLevelName ? this.serviceLevelData.serviceLevelName : '...'),
            }
        },
    },

    created () {
        if (!this.isAllowedTo('getServiceLevelConfigurationById')) {
            return
        }

        const getServiceLevelConfigurationUrl = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.CONFIGURATIONS.GET.replace('{carrierId}', this.$route.params.carrierId).replace('{serviceLevelId}', this.$route.params.serviceLevelId).replace('{serviceLevelConfigurationId}', this.$route.params.serviceLevelConfigurationId)

        this.loading = true
        this.addJob(getServiceLevelConfigurationUrl)
        this.$api.get(getServiceLevelConfigurationUrl).then(({ data }) => {
            this.serviceLevelConfigurationData = data.data
        }).catch(({ data }) => {
            this.$root.$emit('notification:global', {
                message: `Getting service level configuration failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(getServiceLevelConfigurationUrl)
            this.loading = false
        })
    },
}
</script>
